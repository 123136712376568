import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import {homeIcon} from '../utils/helperList';

const navItems = [{
  label: "Home",
  route: "/"
},
{
  label: "About",
  route: "/about"
},
 {
  label: "Skills",
  route: "/skills"
},{
  label: "Contact",
  route: "/contact"
}]

const CustomNav = () => {

  return (
    <Navbar bg="" expand="md" className="nav-color nav-slide">
      
      <Link className="text-decoration-none " to="/">{<img className="logo-img" src={homeIcon.src} alt={homeIcon.label} loading="lazy" />}</Link>
      <Navbar.Toggle className="border-0" aria-controls="basic-navbar-nav  navbar-menu" />
      <Navbar.Collapse id="basic-navbar-nav" className="bg-white navbar-menu">
        <Nav className="ms-auto">
          {navItems.map(item =>
            <NavLink
              exact
              key={item.label}
              className="color-custom-blue w-100 text-decoration-none text-center nav-link mt-2"
              activeClassName="font-weight-bold"
              to={item.route}
            >
              {item.label}
            </NavLink>
          )}
        </Nav>
        <Nav className="">
          <button className="button button-cv mt-3" onClick={() => window.open('/static/Blaze_Mathew_Resume.pdf', '_blank')}>View Resume</button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default CustomNav;

import React from "react";
import CustomNav from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import DeveloperSvg from "../utils/developer";
import Particles from "../utils/particles";

const Home = () => {

  return (
    <>
      
      <Container className="h-100 home-container" role="main">
      <CustomNav />
        <Row xs={1} md={2} className="mt-4">
          <Col className="my-auto">
            <div className="home-content">
              <h5 className="color-custom-grey font-weight-bold mb-0">Hey !</h5>
              <h1 className="color-name-blue text-boldest">I'm Blaze Mathew</h1>
              <div className="color-custom-grey h4">
                  <span>I'm a Full-Stack Developer</span>
                </div>
                <br />
                <span className="color-custom-grey h5 mt-5">I specialized in React, Angular, Nest JS, Graphql, Postman, AWS</span><br />
            
              <div className="d-flex home-content my-4">
                <button variant="" className="color-custom-blue p-0 m-1 border-0 icon-button" aria-label="github" onClick={() => window.open('https://github.com/blazemathew', '_blank')}><FontAwesomeIcon size="2x" icon={faGithubSquare} /></button>
                <button variant="" className="color-custom-blue p-0 m-1 border-0 icon-button" aria-label="linkedin" onClick={() => window.open('https://www.linkedin.com/in/blaze-mathew', '_blank')}><FontAwesomeIcon size="2x" icon={faLinkedin} /></button>
              </div>
              <button className="button" onClick={() => window.open('/static/Blaze_Mathew_Resume.pdf', '_blank')}>View Resume</button>
            </div>
          </Col>
          <Col className=" my-auto">
            <DeveloperSvg />
          </Col>
        </Row>
      </Container>
      <Particles />
      {/* <About /> */}
    </>
  )
};

export default Home;
